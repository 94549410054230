import { Browser } from "@syncfusion/ej2-base";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { DropDownButtonComponent } from "@syncfusion/ej2-react-splitbuttons";
import moment from "moment";
import React, { useEffect } from "react";
import {
  IsNotNullOrWhiteSpace,
  convertUTCDateToLocalTimezone,
  isNotNullAndUndefined,
} from "../helpers/utils";
import "./NavigationStyle.scss";
import { MenuEventArgs } from "@syncfusion/ej2-navigations";
import DOMPurify from "dompurify";

const RAFRecordInfo = (props: {
  createdDate?: Date;
  modifiedDate?: Date;
  lastActivityDate?: Date;
  createdBy?: string;
  modifiedBy?: string;
  content?: any;
  spanContent?: any;
  spanContentClassName?: string;
  tooltip?: boolean;
  clockIconBtn?: "normal" | "small" | "responsive";
  hideClockIcon?: boolean;
  dropdownBtnClassName?: string;
}) => {
  const [outerDivKey, setOuterDivKey] = React.useState(Math.random());

  useEffect(() => {
    const intervalId = setInterval(() => {
      refresh();
    }, 120000); // 120000 milliseconds = 2 minutes

    // Cleanup function to clear the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const refresh = () => {
    setOuterDivKey(Math.random());
  };

  let createdDate = convertUTCDateToLocalTimezone(props.createdDate);
  let modifiedDate = convertUTCDateToLocalTimezone(props.modifiedDate);
  let lastActivityDate;

  if (isNotNullAndUndefined(props.lastActivityDate)) {
    lastActivityDate = convertUTCDateToLocalTimezone(props.lastActivityDate);
  } else if (isNotNullAndUndefined(props.modifiedDate)) {
    lastActivityDate = convertUTCDateToLocalTimezone(props.modifiedDate);
  } else {
    lastActivityDate = null;
  }

  let createdBy = props.createdBy;
  let modifiedBy = props.modifiedBy;

  //let recordInfoDate = moment(new Date(modifiedDate + "Z")).format("MMM/DD hh:mm A").toString();
  //let recordInfoDateTooltip = "Created Date " + moment(new Date(createdDate + "Z")).format("DD/MM/YYYY hh:mm A").toString();
  //let recordInfoCreateByTooltip = "Created By " + createdBy;

  let createdDateTemplate =
    '<div class="row g-0 w-100"> <div class="col-sm-5 dropdownmenu-label"> <span> Created Date </span></div> <div class="col-sm-7 dropdownmenu-value"> <span> ' +
    (isNotNullAndUndefined(createdDate)
      ? moment(createdDate).format("DD/MM/YYYY hh:mm A").toString()
      : "NA") +
    "</span></div></div>";
  let createdByTemplate =
    '<div class="row g-0 w-100"> <div class="col-sm-5 dropdownmenu-label"> <span> Created By </span></div> <div class="col-sm-7 dropdownmenu-value"> <span> ' +
    (isNotNullAndUndefined(createdBy) ? createdBy : "NA") +
    "</span></div></div>";
  let modifiedDateTemplate =
    '<div class="row g-0 w-100"> <div class="col-sm-5 dropdownmenu-label"> <span> Modified Date </span></div> <div class="col-sm-7 dropdownmenu-value"> <span> ' +
    (isNotNullAndUndefined(modifiedDate)
      ? moment(modifiedDate).format("DD/MM/YYYY hh:mm A").toString()
      : "NA") +
    "</span></div></div>";
  let modifiedByTemplate =
    '<div class="row g-0 w-100"> <div class="col-sm-5 dropdownmenu-label"> <span> Modified By </span></div> <div class="col-sm-7 dropdownmenu-value"> <span> ' +
    (isNotNullAndUndefined(modifiedBy) ? modifiedBy : "NA") +
    "</span></div></div>";
  let lastActivityDateTemplate = "";

  if (isNotNullAndUndefined(props.lastActivityDate)) {
    lastActivityDateTemplate =
      '<div class="row g-0 w-100"> <div class="col-sm-5 dropdownmenu-label"> <span> Last Activity Date </span></div> <div class="col-sm-7 dropdownmenu-value"> <span> ' +
      moment(lastActivityDate).format("DD/MM/YYYY hh:mm A").toString() +
      "</span></div></div>";
  }

  let recordInfoDateTooltip =
    '<div class="e-tip-content-item">' +
    createdDateTemplate +
    '</div><div class="e-tip-content-item">' +
    createdByTemplate +
    '</div><div class="e-tip-content-item">' +
    modifiedDateTemplate +
    '</div><div class="e-tip-content-item">' +
    modifiedByTemplate +
    '</div><div class="e-tip-content-item">' +
    lastActivityDateTemplate +
    "</div>";
  let dropdownBtnClassNameValue =
    "e-dropdown-btnDefault recordInfo-dropdown-btn unset-custom-button-md e-caret-hide recordInfo-dropdown";
  let iconDropdownBtnClassName =
    "e-caret-hide recordInfo-dropdown primary-custom-button square-btn";

  let IsResponsive = false;
  if (
    isNotNullAndUndefined(props.clockIconBtn) &&
    props.clockIconBtn === "small"
  ) {
    iconDropdownBtnClassName = "e-small";
  }

  if (isNotNullAndUndefined(props.dropdownBtnClassName)) {
    dropdownBtnClassNameValue =
      props.dropdownBtnClassName + " " + dropdownBtnClassNameValue;
    iconDropdownBtnClassName =
      props.dropdownBtnClassName + " " + iconDropdownBtnClassName;
  }
  if (
    isNotNullAndUndefined(props.clockIconBtn) &&
    props.clockIconBtn === "responsive"
  ) {
    IsResponsive = true;
  }
  const beforeItemRender = (args: MenuEventArgs) => {
    if (args.item.id === "createdBy") {
      args.element.innerHTML = createdByTemplate;
    }
    if (args.item.id === "createdDate") {
      args.element.innerHTML = createdDateTemplate;
    }
    if (args.item.id === "modifiedBy") {
      args.element.innerHTML = modifiedByTemplate;
    }
    if (args.item.id === "modifiedDate") {
      args.element.innerHTML = modifiedDateTemplate;
    }
    if (args.item.id === "lastActivityDate") {
      args.element.innerHTML = lastActivityDateTemplate;
    }
  };

  return isNotNullAndUndefined(lastActivityDate) ? (
    <div className="" key={outerDivKey} onClick={(e) => e.stopPropagation()}>
      {props.tooltip === true ? (
        <div className="">
          <TooltipComponent
            opensOn={Browser.isDevice ? "Click" : "Hover"}
            cssClass="recordInfo-tooltip recordInfo-dropdown recordInfo-dropdown-btn detailsHeader__recordInfo__btn"
            content={recordInfoDateTooltip}
            position={"BottomCenter"}
            tabIndex={0}
          >
            <div className="d-flex align-items-center" tabIndex={-1}>
              <i className="far fa-clock detailsHeader__recordInfo__btn__icon"></i>
              {isNotNullAndUndefined(lastActivityDate) && (
                <span
                  className={
                    isNotNullAndUndefined(props.spanContentClassName)
                      ? props.spanContentClassName
                      : "d-flex detailsHeader__recordInfo__btn__text"
                  }
                >
                  {isNotNullAndUndefined(props.content)
                    ? props.content +
                    moment(lastActivityDate).subtract(20, "s").fromNow()
                    : moment(lastActivityDate).subtract(20, "s").fromNow()}
                </span>
              )}
              <div className="detailsHeader__recordInfo__btn__arrowIcon">
                {" "}
                <i className="far fa-angle-down"></i>
              </div>
            </div>
          </TooltipComponent>
        </div>
      ) : (
        <div className="">
          <div className="recordInfoToggler">
            {isNotNullAndUndefined(props.clockIconBtn) &&
              props.clockIconBtn !== "responsive" ? (
              <DropDownButtonComponent
                cssClass={iconDropdownBtnClassName}
                iconCss="far fa-clock"
                items={[
                  { id: "createdBy", text: createdDateTemplate },
                  { id: "createdDate", text: createdByTemplate },
                  { id: "modifiedBy", text: modifiedDateTemplate },
                  { id: "modifiedDate", text: modifiedByTemplate },
                  { id: "lastActivityDate", text: lastActivityDateTemplate },
                ]}
                beforeItemRender={beforeItemRender}
              ></DropDownButtonComponent>
            ) : (
              <div className="test">
                <DropDownButtonComponent
                  cssClass={
                    IsResponsive === true
                      ? dropdownBtnClassNameValue + " d-none d-md-block"
                      : dropdownBtnClassNameValue
                  }
                  iconCss=""
                  items={[
                    { id: "createdBy", text: createdDateTemplate },
                    { id: "createdDate", text: createdByTemplate },
                    { id: "modifiedBy", text: modifiedDateTemplate },
                    { id: "modifiedDate", text: modifiedByTemplate },
                    { id: "lastActivityDate", text: lastActivityDateTemplate },
                  ]}
                  beforeItemRender={beforeItemRender}
                >
                  <div className="userProfile">
                    <div
                      className="text-nowrap d-flex align-items-center justify-content-center flex-nowrap"
                      id="recordInfo"
                    >
                      {props.hideClockIcon ? (
                        ""
                      ) : (
                        <i className="far fa-clock pe-1"></i>
                      )}
                      {isNotNullAndUndefined(lastActivityDate) && (
                        <div className="d-flex align-items-center">
                          {IsNotNullOrWhiteSpace(props.content) ? (
                            <span
                              className="text_secondary_value text_secondary_value_sm pe-1"
                              dangerouslySetInnerHTML={{
                                __html:
                                  isNotNullAndUndefined(props.content) &&
                                    IsNotNullOrWhiteSpace(props.content)
                                    ? DOMPurify.sanitize(props.content)
                                    : "",
                              }}
                            ></span>
                          ) : (
                            ""
                          )}
                          <span
                            className={
                              isNotNullAndUndefined(props.spanContentClassName)
                                ? props.spanContentClassName
                                : "recordInfo_text"
                            }
                          >
                            {isNotNullAndUndefined(props.spanContent) ? `${props.spanContent} ` : ""}
                            {moment(lastActivityDate)
                              .subtract(20, "s")
                              .fromNow()}
                          </span>
                          {/* {isNotNullAndUndefined(props.content) ? props.content + moment(lastActivityDate).subtract(20, 's').fromNow() :
                                                        moment(lastActivityDate).subtract(20, 's').fromNow()
                                                    } */}
                        </div>
                      )}
                    </div>
                  </div>
                  {/*<span> {userName} </span>*/}
                </DropDownButtonComponent>
                {IsResponsive === true ? (
                  <DropDownButtonComponent
                    cssClass={iconDropdownBtnClassName + " d-md-none"}
                    iconCss="far fa-clock"
                    items={[
                      { id: "createdBy", text: createdDateTemplate },
                      { id: "createdDate", text: createdByTemplate },
                      { id: "modifiedBy", text: modifiedDateTemplate },
                      { id: "modifiedDate", text: modifiedByTemplate },
                      {
                        id: "lastActivityDate",
                        text: lastActivityDateTemplate,
                      },
                    ]}
                    beforeItemRender={beforeItemRender}
                  ></DropDownButtonComponent>
                ) : (
                  <div></div>
                )}
              </div>
            )}
            {/*<TooltipComponent content={recordInfoDate} position='LeftCenter'>
                        <span className="text-nowrap d-flex align-items-center justify-content-center" id="recordInfo">
                            <i className="far fa-clock pe-2"></i>
                            <span className="d-flex" style={{ fontSize: '11px' }}>Last Updated {moment(modifiedDate).subtract(20,'s').fromNow()}</span>
                        </span>

                    </TooltipComponent>*/}
            {/*<MDBTooltip
                    domElement
                    tag="span"
                    placement="bottom">
                    <span className="text-nowrap">
                        <i className="far fa-clock pe-2"></i>
                        <span className="d-none d-sm-inline-flex">2days ago by Admin</span>
                        </span>
                    <span><span>
                        {/*{Utils.formatValue(modifiedDate, { inDateFormat: moment.toString() })}*/}
            {/* {moment(new Date(modifiedDate + "Z")).format("DD/MM/YYYY hh:mm A") }
                    </span></span>

                </MDBTooltip>*/}
          </div>
        </div>
      )}

      {/*
                    <TooltipComponent target='#recordInfo' position='BottomCenter' cssClass="recordInfo-tooltip">
                        
                        <div className="ms-2 d-flex flex-column" id="recordInfo" title={recordInfoDateTooltip + recordInfoCreateByTooltip}>
                            
                        <div className="d-flex flex-column align-items-center" id="recordInfo" title={recordInfoDateTooltip}>
                        <span className="key_header">LAST UPDATED</span>
                        <span className="key_value">{recordInfoDate}</span>
                    </div>
                    </TooltipComponent>*/}
    </div>
  ) : null;
};
export default RAFRecordInfo;
